.btn-primary {
    @apply text-pure bg-primaryLight font-medium rounded-lg cursor-pointer disabled:cursor-not-allowed duration-300 ease-in-out; 
}
.btn-primary:hover {
    @apply bg-primary;
}

.primary-v2.btn-primary {
    @apply border border-transparent;
}
.primary-v2.btn-primary:hover {
    @apply border-white;
}

.btn-secondary {
    @apply border border-transparent bg-secondary text-pure font-medium rounded-lg cursor-pointer disabled:cursor-not-allowed duration-300 ease-in-out; 
}
.btn-secondary:hover {
    @apply border-secondary text-black bg-transparent;
}

.btn-red {
    @apply text-pure font-medium rounded-full cursor-pointer disabled:cursor-not-allowed bg-red-500; 
}

.btn-green-circle , .btn-red-circle , .btn-primary-circle {
    @apply bg-green-500 w-[25px] h-[25px] rounded-full flex items-center justify-center text-white text-base cursor-pointer disabled:cursor-not-allowed;
}


.section-heading {
    @apply font-semibold lg:text-4xl md:text-3xl text-2xl  ; 
}

.heading {
    @apply font-semibold sm:text-xl text-lg capitalize ; 
}

.heading-sm{
    @apply font-semibold text-base ;
}

.shadow-bg {
    @apply bg-pure rounded-lg shadow-md border;
}

.input  , .copy-input{
    @apply border py-3 px-3 text-gray-600 rounded-md outline-none w-full ;
}
.input::placeholder , .copy-input::placeholder {
    color : rgb(163, 163, 163);
}
.input:focus{
    border-color: var(--primary);
}

table {
    white-space: nowrap;
}

::-webkit-scrollbar{
    width: 5px;
}
::-webkit-scrollbar-track{
    background-color: white;
}
::-webkit-scrollbar-thumb{
    background-color: gray;
}
::-webkit-scrollbar-thumb:hover{
    background-color: var(--primary);
}



/* FORMIK/FORM */
.form-row {
    @apply flex flex-col gap-1.5 flex-1 w-full ;
}
.form-row label , .label {
    @apply font-semibold text-gray-600;
}
.form-row input , .form-row select , .form-row textarea , .input {
    @apply border py-3 px-3 text-gray-600 rounded-md outline-none w-full;
    
}
.form-row input:focus-within , .form-row select:focus-within , .form-row textarea:focus-within , .input:focus-within {
    @apply border-primary;
}
.form-row .error-msg , .error-msg {
    @apply text-red-500 text-sm ;
}
.border-red {
    @apply border border-red-500;
}
.input-error {
    border: 1px solid #ff0000 !important; /* Red border for error */
}


.popup {
    animation: show 0.15s ease-in-out;
}

@keyframes show {
    from {
        transform: scale(0.3);
    }
    to {
        transform: scale(1);
    }
}