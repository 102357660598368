@tailwind base;
@tailwind components;
@tailwind utilities;
 
html {
    font-family: "Roboto", sans-serif;
    scroll-behavior: smooth;
}

:root {
    --primary : #090C9B;
    --primaryLight : #497DFF;
    --secondary : #F65800;
    --grayText : #686868;
    --dark : #3a3a3a ;
    --pure : #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


/* React Confirm Alert */
.react-confirm-alert-overlay {
    background : #000000bd !important;
}
.react-confirm-alert-body {
    border-radius: 8px !important;
    background : var(--primary);
    color : var(--dark) !important;
    padding: 20px !important;
}
.react-confirm-alert-body h1 {
    @apply text-xl font-semibold mb-2 text-primary;
}
.react-confirm-alert-body button{
    @apply mt-4;
}
.react-confirm-alert-body button:first-child{
    @apply bg-primary hover:bg-primaryLight;
}


.vertical-timeline::before {
    background: var(--primary) !important; /* Your desired color */
}